<template>
  <div class="confirm width100">
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'confirm'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="mx-auto d-block" src="@/assets/icon/mail-confirm.svg" alt="confirm" />
      <div class="f28 font-weight-bold primary--text text--darken-2 text-center my-5">Confirm your email address</div>
      <v-btn @click="confirm" block height="48" color="primary">
        <span class="f15 font-weight-bold">Continue</span>
      </v-btn>
    </v-card>
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'expired'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="mx-auto d-block" src="@/assets/icon/expired.svg" alt="expired" />
      <div class="f28 font-weight-bold primary--text text--darken-2 text-center mt-5">Email confirmation link expired</div>
    </v-card>
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'already'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="mx-auto d-block" src="@/assets/icon/already.svg" alt="already" />
      <div class="f28 font-weight-bold primary--text text--darken-2 text-center mt-5">Email address is already confirmed</div>
    </v-card>
    <v-card elevation="0" max-width="500" min-height="440" rounded="lg" class="pa-12 width100" v-if="card == 'invalid'">
      <div class="primary--text text--darken-2 mb-14 link" @click="back">
        <v-icon class="mb-1" color="primary darken-2">mdi-arrow-top-left mdi-rotate-315 mdi-18px</v-icon> Back to Log In
      </div>
      <img class="mx-auto d-block" src="@/assets/icon/invalid.svg" alt="invalid" />
      <div class="f28 font-weight-bold primary--text text--darken-2 text-center mt-5">Email confirmation link is invalid</div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      card: 'confirm',
      error: [],
    };
  },
  methods: {
    back() {
      this.$router.push('/sign-in');
    },
    async confirm() {
      this.error = [];
      const data = new Object();
      data.token = this.$route.query.t;
      await this.$store
        .dispatch('confirmEmail', data)
        .then((res) => {
          sessionStorage.setItem('jwt_token', res.data.access_token);
          this.$router.push('/');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((i) => i == 'token__invalid')) {
            this.card = 'invalid';
          } else if (this.error.find((i) => i == 'token__expired')) {
            this.card = 'expired';
          } else if (this.error.find((i) => i == 'token__already_used')) {
            this.card = 'already';
          }
        });
    },
  },
};
</script>
